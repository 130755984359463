import "../styles/App.css";

import yinyang from "../images/yinyang.png";
import GuestSearchForm from "./GuestSearchForm";
import { UserContext } from "../App";
import { useContext } from "react";
import { ErrorGuest, handleScroll } from "../constants";

function Invitation() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {currentUser, setCurrentUser} = useContext(UserContext);

    let titleElement = 
        <div>
            <text className="title">
                Amy Lin &
            </text>
            <text className="title">
                Alexander Fang
            </text>
        </div>;

    if (window.screen.width <=800) {
        titleElement = 
            <div>
                <text className="title">
                    Amy Lin &
                </text>
                <text className="title">
                    Alexander
                </text>
                <text className="title">
                    Fang
                </text>
            </div>;
    }

    return (
        <div className="header">
            <div className="logoButton">
                <a className="yinyang" href="https://www.linandfang.com/" rel="noopener noreferrer">
                    <img src={yinyang} className="yinyang" alt="yinyang" />
                </a>
                <svg viewBox="125 105 250 50">
                    <path fill="none" d="M370 125c0 66.3-53.7 120-120 120s-120-53.7-120-120S183.7 5 250 5s120 53.7 120 120Z" />
                </svg>
            </div>
            {/* <div className="hide">#LinAndFang</div> */}
            <div className="headerWrapper">
                <text className="backgroundHeader">
                    Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and 
                </text><br/>
                <text className="backgroundHeader">
                    and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and 
                </text><br/>
                <text className="backgroundHeader">
                    nd Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and 
                </text><br/>
                <text className="backgroundHeader">
                    Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and 
                </text><br/>
                <text className="backgroundHeader">
                    in and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and 
                </text><br/>
                <text className="backgroundHeader">
                    ang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and 
                </text><br/>
                <text className="backgroundHeader">
                    Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and Fang and Lin and 
                </text><br/>
            </div>
            <br /><br/>
            {titleElement}
            <br />
            <br />
            <div className="narrowColumn">
                <text className="bigCenteredText">
                    Request the pleasure of your company on our wedding weekend
                </text>
                <br/>
                <br/>
                <text>
                    OCTOBER 24-27, 2024
                </text>
                <br/>
                <text>
                    CASA PARQUE
                </text>
                <text>
                    CASA de SIERRA NEVADA
                </text>
                <text>
                    SAN MIGUEL de ALLENDE
                </text>
                <text>
                    MEXICO
                </text>
                <br/><br/>
                {currentUser===null && currentUser!==ErrorGuest ? <text> Look up your invitation</text> : null}
                <GuestSearchForm/>
                <br/>
                <div className="buttonBox">
                    <button type="button" onClick={e => handleScroll("form")}>RSVP</button>
                </div><br/>
                <text className="tinyText">
                    RSVP by September 6th
                </text>
                <br/>
                <br/>
                <div className="wrapper">
                    <div className="buttonBox">
                        <button className="smallButtons" type="button" onClick={e => handleScroll("schedule")}>Schedule</button>
                    </div><br/>
                    <div className="buttonBox">
                        <button className="smallButtons" type="button" onClick={e => handleScroll("faq")}>FAQs</button>
                    </div><br/>
                    <div className="buttonBox">
                        <button className="smallButtons" type="button" onClick={e => handleScroll("registry")}>Registry</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invitation;
