import "./styles/App.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Route, Routes, BrowserRouter, Link } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import { useState, createContext } from "react";

export const UserContext = createContext(null as any);

const App = () => {
    const [currentUser, setCurrentUser] = useState(null);

    return (
        <div className="App" id="top">
            <BrowserRouter>
                <header className="header">
                    {/* <div className="wrapper"> */}
                        {/* <div className="menu"> */}
                            {/* <a className="logo" href="https://www.linandfang.com/" rel="noopener noreferrer">
                                <img src={yinyang} className="yinyang" alt="yinyang" />
                            </a>
                            <br /> */}
                            {/* <Link to="/about">
                                <button className="menuItem">About</button>
                            </Link> */}
                            {/* <Link to="/services">
                                <button className="menuItem">Services</button>
                            </Link> */}
                        {/* </div> */}
                    <UserContext.Provider value={{
                        currentUser,
                        setCurrentUser
                    }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/about" element={<Services />} />
                        </Routes>
                    </UserContext.Provider>
                    {/* </div> */}
                </header>
            </BrowserRouter>
        </div>
    );
}

export default App;
