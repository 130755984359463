import diagonalArrow from "../icons/diagonal-arrow.svg";


function About() {
    return (
        <div className="About">
            <div className="wrapper">
                <div className="column">
                    {/* <img src={headshot} className="headshot" alt="headshot" /> */}
                    <div className="photoCreditRow">
                        <text className={"photoCredit"}>Photo courtesy of&nbsp;</text>
                        <div>
                            <a className="photoCreditLink" href="https://www.instagram.com/maxituyip.art" target="_blank" rel="noopener noreferrer">
                                Maxi Tu Yip
                            </a>
                            <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
                        </div>
                    </div>
                </div>
                <div className="column">
                    {/* <BasicInfo /> */}
                    <br />
                    <br />
                    {/* <BioAndSocials short={false} /> */}
                </div>
            </div>
        </div>
    );
}

export default About;
