import { useContext, useState } from "react";
// import jsonData from "../responses.json";
import { UserContext } from "../App";
import { ErrorGuest, Guest } from "../constants";
import { searchForSingleGuest } from "../services";

function GuestSearchForm() { 
    const {currentUser, setCurrentUser} = useContext(UserContext);
    const [searchedName, setSearchedName] = useState<string | undefined>(undefined);
    const [searchPressed, setSearchPressed] = useState<boolean | undefined>(undefined);
    const [multipleUsersFound, setMultipleUsersFound] = useState<boolean>(false);

    const search = () => {
        setSearchPressed(true);
        if (!!searchedName) {
            searchForSingleGuest(searchedName).then(
                (guestResponseStatus) => {
                    if (guestResponseStatus.status === 503) {
                        console.log('Search single guest failed... service unavailable', guestResponseStatus.text);
                        setCurrentUser(ErrorGuest);
                    } else if (guestResponseStatus.status === 409) {
                        console.log('Search single guest failed... multiple matches found', guestResponseStatus.text);
                        setCurrentUser(ErrorGuest);
                        setMultipleUsersFound(true);
                    } else if (guestResponseStatus.status === 404) {
                        console.log('Search single guest failed... unknown error', guestResponseStatus.text);
                        setCurrentUser(ErrorGuest);
                    } else {
                        console.log('Search single guest success!');
                        setCurrentUser(guestResponseStatus.guest);
                    }
                    setSearchPressed(false);
                },
                (error) => {
                    console.log('Search single guest failed...', error.text);
                    setCurrentUser(ErrorGuest);
                    setSearchPressed(false);
                }
            );
        } else {
            setCurrentUser(ErrorGuest);
            setSearchPressed(false);
        }
    };

    const searchName = (event: React.ChangeEvent<HTMLInputElement>) => {
        // onChange(event);
        if (searchedName===undefined) {
            setSearchedName(event.target.value);
        }   
    }

    const form = (
        <div className="formWrapper">
            <div className="formBox">
                {currentUser===null 
                    ? (
                        <div className="searchArea">
                            <label>
                                Name:&nbsp;
                                <input name="guestName" id="guestName" type="guestName" required onBlur={(val) => searchName(val)}/>
                            </label>
                            {searchPressed ? <button className="guestSearchButton" type="button" disabled>Searching...</button> : <button className="guestSearchButton" type="button" onClick={(val) => search()}>Search</button>}
                            <br/>
                        </div>
                    ) : currentUser===ErrorGuest 
                        ? multipleUsersFound 
                            ? <label className="simpleLabelCentered">Multiple matches found.<br/>Please reload and search by your full name.</label>
                            :(
                                <label className="simpleLabelCentered">
                                    We couldn't find your invitation.<br/>Please contact the hosts.
                                </label>
                        ) : (
                            <label>
                                Hi {(currentUser as Guest).nickname ?? (currentUser as Guest).firstName}!
                            </label>
                        )
                }
            </div>
        </div>
    );

    return form;
}

export default GuestSearchForm;
