
function Services() {
    return (
        <>
            <div className="column">
                {/* <div className="photoRow"> */}
                {/* <img src={essayFlyer} className="flyer" alt="flyer" /> */}
            </div>
            <div className="column">
                {/* <img src={pianoFlyer} className="flyer" alt="flyer" /> */}
            </div>
        </>
    );
}

export default Services;
