import { ErrorGuest, Guest, GuestResponseStatus, InvitationResponseStatus, RSVPSubmitResponseStatus, TeaCeremonyRole} from "./constants";

export async function searchForSingleGuest(input: string): Promise<GuestResponseStatus> {
    const names = input.split(" ");
    let url = new URL('https://gentle-springs-10500-0a40989b5ae6.herokuapp.com/search-single');
    
    if (names.length===1) {
        url = new URL('https://gentle-springs-10500-0a40989b5ae6.herokuapp.com/search-single-nickname');
        url.searchParams.append('name', names[0]);
    } else {
        url.searchParams.append('first', names[0]);
        url.searchParams.append('last', names[names.length-1]);
    }

    try {
        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) {
            const error = new Error(`Error: ${response.status} ${response.statusText}`);
            console.error(`Error: ${response.status} ${response.statusText}`, error);
            throw error;
        }
    
        const result: any = await response.json();

        const mappedData: Guest = mapToGuest(result);

        return {
            status: response.status,
            text: response.statusText,
            guest: mappedData
        }
    } catch (e) {
        const error = (e as Error);
        if (error.message === "Failed to fetch") {
            return {
                status: 503,
                text: error.message + "; " + error.stack,
                guest: ErrorGuest
            };
        } else if (error.message === "Error: 409 Conflict") {
            return {
                status: 409,
                text: error.message + "; " + error.stack,
                guest: ErrorGuest
            };
        }

        return {
            status: 404,
            text: "Error searching single guest",
            guest: ErrorGuest
        };
    }    
}

export async function getGuestsOnSameInvitation(id: string): Promise<InvitationResponseStatus> {
    const url = new URL('https://gentle-springs-10500-0a40989b5ae6.herokuapp.com/search-invite-id');
    url.searchParams.append('id', id);

    try {
        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) {
            const error = new Error(`Error: ${response.status} ${response.statusText}`);
            console.error(`Error: ${response.status} ${response.statusText}`, error);
            throw error;
        }
    
        const result: Array<any> = await response.json();

        const mappedData: Guest[] = result.map(mapToGuest);

        return {
            status: response.status,
            text: response.statusText,
            invitations: mappedData
        }
    } catch (error) {
        return {
            status: 404,
            text: "Error searching RSVP",
            invitations: [ErrorGuest]
        }
    }    
};

const mapToGuest = (invitation: any): Guest => {
    const mealChoice = invitation["ReceptionMealChoice"] === null ? undefined : invitation["ReceptionMealChoice"];
    const rehearsalDinnerMealChoice1 = invitation["RehearsalDinnerChoice1"] === null ? undefined : invitation["RehearsalDinnerChoice1"];
    const rehearsalDinnerMealChoice2 = invitation["RehearsalDinnerChoice2"] === null ? undefined : invitation["RehearsalDinnerChoice2"];

    return {
        title: invitation["Title"],
        firstName: invitation["FirstName"],
        lastName: invitation["LastName"],
        nickname: invitation["Nickname"],
        pronoun1: invitation["Pronoun1"],
        pronoun2: invitation["Pronoun2"],
        pronoun3: invitation["Pronoun3"],
        id: invitation["GuestID"],
        rsvpId: invitation["InviteID"],
        rsvpLocked: invitation["Locked"],
        rsvpGoing: invitation["FinalRSVP"]==="yes",
        mealChoice: mealChoice,
        rehearsalRequired: invitation["RehearsalReq"]==="yes",
        rehearsalDinnerInvited: invitation["RehearsalDinnerInvite"]==="yes",
        rehearsalDinnerGoing: invitation["RehearsalDinnerRSVP"]==="yes",
        rehearsalDinnerMealChoice1: rehearsalDinnerMealChoice1,
        rehearsalDinnerMealChoice2: rehearsalDinnerMealChoice2,
        teaCeremonyInvited: invitation["TeaRole"]!=="no",
        teaCeremonyGoing: invitation["TeaRSVP"]==="yes",
        teaCeremonyRole: mapTeaCeremonyRole(invitation["TeaRole"]),
        welcomePartyGoing: invitation["WelcomeRSVP"]==="yes",
        bus: invitation["FinalBusRSVP"]==="yes",
        message: invitation["Message"]
    };
};

const mapTeaCeremonyRole = (rawRole: string): TeaCeremonyRole => {
    switch (rawRole) {
        case "participating": return TeaCeremonyRole.Participant;
        case "attending": return TeaCeremonyRole.Viewer;
        case "staffing": return TeaCeremonyRole.Staff;
        default: return TeaCeremonyRole.NotInvited;
    }
}

export type InvitationDisplay = {
    invitationOrder: Guest[],
    invitationCopy: string,
    lockedInvitationCopy: string
};

export async function submitRSVP(values: {}): Promise<RSVPSubmitResponseStatus> {
    let url = new URL('https://gentle-springs-10500-0a40989b5ae6.herokuapp.com/update');

    try {
        const response = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        });
    
        if (!response.ok) {
            const error = new Error(`Error: ${response.status} ${response.statusText}`);
            console.error(`Error: ${response.status} ${response.statusText}`, error);
            throw error;
        }

        return {
            status: response.status,
            text: response.statusText
        }
    } catch (e) {
        return {
            status: 404,
            text: "Error submitting RSVP",
        };
    }    
}
