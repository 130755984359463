import { useContext, useState } from "react";
import { Shuttle, ErrorGuest, Guest, RSVP, Event, Meal, TeaCeremonyRole } from "../constants";
import { useForm } from "../useForm";
// import jsonData from "../responses.json";
import { UserContext } from "../App";
import { InvitationDisplay, getGuestsOnSameInvitation, searchForSingleGuest, submitRSVP } from "../services";

function RSVPForm() {
    // defining the initial state for the form
    const initialState = {};

    const [formSubmitted, setFormSubmitted] = useState<boolean | undefined>(undefined);
    // const [responseData, setResponseData] = useState<CompletedForm[]>(jsonData);

    const [searchedName, setSearchedName] = useState<string | undefined>(undefined);
    const [searchPressed, setSearchPressed] = useState<boolean | undefined>(undefined);
    const {currentUser, setCurrentUser} = useContext(UserContext);
    const [guestDisplay, setGuestDisplay] = useState<InvitationDisplay | undefined>(undefined);
    const [multipleUsersFound, setMultipleUsersFound] = useState<boolean>(false);
    const [rsvpFound, setRsvpFound] = useState<boolean>(false);

    // getting the event handlers from our custom hook
    const { onChange, onSubmit, values } = useForm(sendFormCallback, initialState);

    // a submit function that will execute upon form submission
    async function sendFormCallback() {
        // send "values" to database
        // const updatedResponseData: CompletedForm[] = [...responseData];
        // updatedResponseData.push(values);
        // setResponseData(updatedResponseData);

        submitRSVP(values).then(
            () => {
                console.log("values:");
                console.log(values);
                console.log('SUCCESS!');
                setFormSubmitted(true);
            },
            (error) => {
                console.log('FAILED...', error.text);
                setFormSubmitted(false);
            }
        );
    };

    const search = () => {
        setSearchPressed(true);
        if (!!searchedName) {
            searchForSingleGuest(searchedName).then(
                (guestResponseStatus) => {
                    if (guestResponseStatus.status === 503) {
                        console.log('Search single guest failed... service unavailable', guestResponseStatus.text);
                        setCurrentUser(ErrorGuest);
                    } else if (guestResponseStatus.status === 409) {
                        console.log('Search single guest failed... multiple matches found', guestResponseStatus.text);
                        setCurrentUser(ErrorGuest);
                        setMultipleUsersFound(true);
                    } else if (guestResponseStatus.status === 404) {
                        console.log('Search single guest failed... unknown error', guestResponseStatus.text);
                        setCurrentUser(ErrorGuest);
                    } else {
                        console.log('Search single guest success!');
                        setCurrentUser(guestResponseStatus.guest);
                    }
                    setSearchPressed(false);
                },
                (error) => {
                    console.log('Search single guest failed...', error.text);
                    setCurrentUser(ErrorGuest);
                    setSearchPressed(false);
                }
            );
        } else {
            setCurrentUser(ErrorGuest);
            setSearchPressed(false);
        }
    };

    let orderedGuests: Guest[] = [];
    const singleGuestCopy = "You are invited to the following events. Please RSVP for each event and indicate your meal preferences."
    const multipleGuestCopy = " are invited to the following events. Please RSVP for each person on your invitation, for each event, and indicate everyone's meal preferences."
    const singleGuestLockedCopy = "You are invited to the following events."
    const multipleGuestLockedCopy = " are invited to the following events."

    const getRelatedGuestsOrderAndCopy = (user: Guest) => {
        getGuestsOnSameInvitation(user.rsvpId).then(
            (invitationResponseStatus) => {
                console.log('Invitation fetch success!');
                let copy = "";
                let lockedCopy = "";
                const guestsOnSameInvitation = invitationResponseStatus.invitations;
                const numGuests = invitationResponseStatus.invitations.length;
    
                if (numGuests===1) {
                    copy = singleGuestCopy;
                    lockedCopy = singleGuestLockedCopy;
                    orderedGuests = guestsOnSameInvitation;
                } else {
                        orderedGuests = guestsOnSameInvitation.sort((a: Guest, b:Guest) => {
                        if (a.firstName === user.firstName && a.lastName === user.lastName) {
                            return -1;
                        } else if (b.firstName === user.firstName && b.lastName === user.lastName) {
                            return 1;
                        } else return 0;
                    });
    
                    if (numGuests===2) {
                        copy = guestsOnSameInvitation[0].firstName+" and "+guestsOnSameInvitation[1].firstName+multipleGuestCopy;
                        lockedCopy = guestsOnSameInvitation[0].firstName+" and "+guestsOnSameInvitation[1].firstName+multipleGuestLockedCopy;
                    } else {
                        const lastGuest = guestsOnSameInvitation[numGuests-1];
                        guestsOnSameInvitation.forEach((guest: Guest) => {
                            if (lastGuest === guest) {
                                copy = copy + "and " + guest.firstName + multipleGuestCopy;
                                lockedCopy = lockedCopy + "and " + guest.firstName + multipleGuestLockedCopy;
                            } else {
                                copy = copy + guest.firstName + ", ";
                                lockedCopy = lockedCopy + guest.firstName + ", ";
                            }
                        });
                    }
                }
                if (guestDisplay===undefined) {
                    setGuestDisplay({invitationOrder: [...orderedGuests], invitationCopy: copy, lockedInvitationCopy: lockedCopy});
                }   

                setRsvpFound(true);
            },
            (error) => {
                console.log('Invitation fetch failed...', error.text);
            }
        );
    };
    
    const userFound = currentUser!==null && currentUser!==ErrorGuest;
    
    if (userFound && !rsvpFound) {
        getRelatedGuestsOrderAndCopy(currentUser as Guest);
    }

    let questions: JSX.Element[] = [];

    const rsvpQuestion = (eventName: string, guest: Guest, onlyGuestOrCurrentUser: boolean) => {
        let question = "Will "+guest.firstName+" attend "+eventName+ "?";
        const id = `${guest.id}_rsvpOption_${eventName}`;
        let pronoun1 = guest.pronoun1;
        let pronoun2 = guest.pronoun2;
        let pronoun3 = guest.pronoun3;

        if (onlyGuestOrCurrentUser) {
            pronoun1 = "I";
            pronoun2 = "me";
            pronoun3 = "your";
        }

        if (guest.rsvpLocked) {
            let rsvp: boolean | null = null;
            switch(eventName) {
                case Event.CeremonyAndReception:
                    rsvp = guest.rsvpGoing;
                    break;
                case Event.RehearsalDinner:
                    rsvp = guest.rehearsalDinnerGoing;
                    break;
                case Event.TeaCeremony:
                    rsvp = guest.teaCeremonyGoing;
                    break;
                case Event.WelcomeParty:
                    rsvp = guest.welcomePartyGoing;
                    break;
            }

            if (rsvp && eventName === Event.TeaCeremony && guest.teaCeremonyRole) {
                switch(guest.teaCeremonyRole) {
                    case TeaCeremonyRole.Participant:
                        return (
                            <div>
                                <br/>
                                <label className="simpleLabel">
                                    {guest.firstName} is attending as a participant.
                                </label>
                            </div>
                        );
                    case TeaCeremonyRole.Viewer:
                        return (
                            <div>
                                <br/>
                                <label className="simpleLabel">
                                    {guest.firstName} is attending as a member of the audience.
                                </label>
                            </div>
                        );
                    case TeaCeremonyRole.Staff:
                        return (
                            <div>
                                <br/>
                                <label className="simpleLabel">
                                    {guest.firstName} is attending as a member of the staff.
                                </label>
                            </div>
                        );
                    default:
                        return (
                            <div>
                                <br/>
                                <label className="simpleLabel">
                                    {guest.firstName} is not invited.
                                </label>
                            </div>
                        );
                }
            } else if (rsvp) {
                return (
                    <div>
                        <br/>
                        <label className="simpleLabel">
                            {guest.firstName} is attending.
                        </label>
                    </div>);
            } else {
                return (
                    <div>
                        <br/>
                        <label className="simpleLabel">
                            {guest.firstName} will not be attending.
                        </label>
                    </div>);
            }
        } else {
            if (eventName === Event.TeaCeremony && guest.teaCeremonyRole) {
                switch(guest.teaCeremonyRole) {
                    case TeaCeremonyRole.Participant:
                        question = "Will "+guest.firstName+" attend "+eventName+ " as an honored participant?";
                        break;
                    case TeaCeremonyRole.Viewer:
                        question = "Will "+guest.firstName+" attend "+eventName+ " as an audience member?";
                        break;
                    case TeaCeremonyRole.Staff:
                        question = "Will "+guest.firstName+" attend "+eventName+ " to help run the event?";
                        break;
                    default:
                        question = "Will "+guest.firstName+" attend "+eventName+ "?";
                }
            } 

            return (
                <div>
                    <br/>
                    <label>
                        {question}<br/>
                        <select name={id} id={id} key={id} required defaultValue={""} onChange={(val) => onChange(val)}>
                            <option value={""} disabled>Select {pronoun3} response</option>
                            <option value={RSVP.Yes}>Yes, {pronoun1} will attend.</option>
                            <option value={RSVP.No}>Sorry, {pronoun1} can't make it, but thank you for thinking of {pronoun2}.</option>
                        </select>
                    </label>
                    <br/>
                </div>);
        }
    }

    const mealQuestion = (eventName: string, guest: Guest, onlyGuestOrCurrentUser: boolean) => {
        const question = "What is "+guest.firstName+"'s meal choice? ";
        const id = `${guest.id}_selectedMeal_${eventName}`;
        let pronoun3 = guest.pronoun3;

        if (onlyGuestOrCurrentUser) {
            pronoun3 = "your";
        }

        if (guest.rsvpLocked) {
            let rsvp: boolean | null = null;
            let mealChoice: Meal[] = [];
            switch(eventName) {
                case Event.CeremonyAndReception:
                    rsvp = guest.rsvpGoing;
                    if (guest.mealChoice) {
                        mealChoice.push(guest.mealChoice);
                    }
                    break;
                case Event.RehearsalDinner:
                    rsvp = guest.rehearsalDinnerGoing;
                    if (guest.rehearsalDinnerMealChoice1) {
                        mealChoice.push(guest.rehearsalDinnerMealChoice1);
                    }
                    if (guest.rehearsalDinnerMealChoice2) {
                        mealChoice.push(guest.rehearsalDinnerMealChoice2);
                    }
                    break;
                case Event.TeaCeremony:
                    rsvp = guest.teaCeremonyGoing;
                    break;
                case Event.WelcomeParty:
                    rsvp = guest.welcomePartyGoing;
                    break;
            }

            if (rsvp && mealChoice.length === 2) {
                return (
                    <div>
                        <label className="simpleLabel">
                            {guest.firstName} will be having {mealChoice[0]} and {mealChoice[1]}.
                        </label>
                        <br/>
                    </div>);
            }
            if (rsvp && mealChoice.length === 1) {
                return (
                    <div>
                        <label className="simpleLabel">
                            {guest.firstName} will be having {mealChoice[0]}.
                        </label>
                        <br/>
                    </div>);
            } else if (rsvp && mealChoice.length === 0) {
                return (
                    <div>
                        <label className="simpleLabel">
                            {guest.firstName} has not chosen a meal. Please contact the hosts to submit your meal preference.
                        </label>
                        <br/>
                    </div>);
            } else {
                return (
                    <div>
                        <label className="simpleLabel">
                            {guest.firstName} has not yet RSVPed.
                        </label>
                        <br/>
                    </div>);
            }
        } else if (eventName===Event.RehearsalDinner){
            const id1 = `${guest.id}_selectedMeal_${eventName}_1`;
            const id2 = `${guest.id}_selectedMeal_${eventName}_2`;

            return (
                <div>
                    <br/>
                    <label>
                        What is {guest.firstName}'s appetizer choice?<br/>
                        <select name={id1} id={id1} key={id1} multiple={false} defaultValue={""} onChange={(val) => onChange(val)}>
                        <option value={""} disabled>Select {pronoun3} meal</option>
                            <option value={Meal.Seafood}>Seafood</option>
                            <option value={Meal.Vegetarian}>Vegetarian</option>
                        </select>
                    </label>
                    <br/><br/>
                    <label>
                        What is {guest.firstName}'s entrée choice?<br/>
                        <select name={id2} id={id2} key={id2} multiple={false} defaultValue={""} onChange={(val) => onChange(val)}>
                        <option value={""} disabled>Select {pronoun3} meal</option>
                            <option value={Meal.Meat}>Beef</option>
                            <option value={Meal.Vegetarian}>Vegetarian</option>
                        </select>
                    </label>
                    <br/>
                </div>);
        } else {
            return (
                <div>
                    <br/>
                    <label>
                        {question}<br/>
                        <select name={id} id={id} key={id} multiple={false} defaultValue={""} onChange={(val) => onChange(val)}>
                        <option value={""} disabled>Select {pronoun3} meal</option>
                            <option value={Meal.Meat}>Pork</option>
                            <option value={Meal.Seafood}>Fish</option>
                            <option value={Meal.Vegetarian}>Vegetarian</option>
                        </select>
                    </label>
                    <br/>
                </div>);
        }
    }

    const shuttleQuestion = (guest: Guest, onlyGuestOrCurrentUser: boolean) => {
        const question = "Is "+guest.firstName+" interested in the Sunday 10/27 shuttle from San Miguel de Allende to Mexico City?";
        const id = `${guest.id}_busOption`;
        let pronoun1 = guest.pronoun1;
        let pronoun2 = guest.pronoun2;
        let pronoun3 = guest.pronoun3;

        if (onlyGuestOrCurrentUser) {
            pronoun1 = "I";
            pronoun2 = "me";
            pronoun3 = "my";
        }

        if (guest.rsvpLocked) {
            if (guest.bus) {
                return (
                    <div>
                        <br/>
                        <label className="simpleLabel">
                            {guest.firstName} has reserved a seat on the shuttle.
                        </label>
                    </div>
                );
            } else {
                return (
                    <div>
                        <br/>
                        <label className="simpleLabel">
                            {guest.firstName} is not interested in the shuttle.
                        </label>
                    </div>
                );
            }
        } else {
            return (
                <div>
                    <br/>
                    <label>
                        {question}<br/>
                        <text className="greenText"><i>&#40;we will be in contact to confirm the booking&#41;</i></text><br/>
                        <select name={id} id={id} key={id} defaultValue={""} onChange={(val) => onChange(val)}>
                            <option value={""} disabled>Select {pronoun3} response</option>
                            <option value={Shuttle.Yes}>Yes, please book a seat for {pronoun2}.</option>
                            <option value={Shuttle.No}>No, {pronoun1} will arrange {pronoun3} own travel post-wedding.</option>
                        </select>
                    </label>
                </div>
            );
        }

        
    }

    const generateQuestions = () => {
        if (!!guestDisplay) {
            // Wedding Day
            questions = questions.concat(<text className="bigColoredText" key="weddingDay">WEDDING DAY</text>)
            guestDisplay.invitationOrder.forEach((guest: Guest) => {
                const onlyGuestOrCurrentUser = guestDisplay.invitationOrder.length===1 || (currentUser!==null && currentUser!==ErrorGuest && guest.firstName===(currentUser as Guest).firstName && guest.lastName===(currentUser as Guest).lastName);
                questions = questions.concat(rsvpQuestion(Event.CeremonyAndReception, guest, onlyGuestOrCurrentUser));
                questions = questions.concat(mealQuestion(Event.CeremonyAndReception, guest, onlyGuestOrCurrentUser));
            });

            // Welcome Party
            questions = questions.concat(<div><br/><br/><text className="bigColoredText" key="welcomeParty">WELCOME PARTY</text></div>)
            guestDisplay.invitationOrder.forEach((guest: Guest) => {
                const onlyGuestOrCurrentUser = guestDisplay.invitationOrder.length===1 || (currentUser!==null && currentUser!==ErrorGuest && guest.firstName===(currentUser as Guest).firstName && guest.lastName===(currentUser as Guest).lastName);
                questions = questions.concat(rsvpQuestion(Event.WelcomeParty, guest, onlyGuestOrCurrentUser));
            });

            // Rehearsal Dinner
            // This assumes if the current user is invited, then everyone on their invitation is also invited.
            if (currentUser!==null && currentUser!==ErrorGuest && (currentUser as Guest).rehearsalDinnerInvited) {
                questions = questions.concat(<div><br/><br/><text className="bigColoredText" key="rehearsalDinner">REHEARSAL DINNER</text></div>)
                guestDisplay.invitationOrder.forEach((guest: Guest) => {
                    const onlyGuestOrCurrentUser = guestDisplay.invitationOrder.length===1 || (currentUser!==null && currentUser!==ErrorGuest && guest.firstName===(currentUser as Guest).firstName && guest.lastName===(currentUser as Guest).lastName);
                    questions = questions.concat(rsvpQuestion(Event.RehearsalDinner, guest, onlyGuestOrCurrentUser));
                    questions = questions.concat(mealQuestion(Event.RehearsalDinner, guest, onlyGuestOrCurrentUser));
                });
            }

            // Tea Ceremony
            // This assumes if the current user is invited, then everyone on their invitation is also invited.
            if (currentUser!==null && currentUser!==ErrorGuest && (currentUser as Guest).teaCeremonyInvited) {
                questions = questions.concat(<div><br/><br/><text className="bigColoredText" key="teaCeremony">TEA CEREMONY</text></div>)
                guestDisplay.invitationOrder.forEach((guest: Guest) => {
                    const onlyGuestOrCurrentUser = guestDisplay.invitationOrder.length===1 || (currentUser!==null && currentUser!==ErrorGuest && guest.firstName===(currentUser as Guest).firstName && guest.lastName===(currentUser as Guest).lastName);
                    questions = questions.concat(rsvpQuestion(Event.TeaCeremony, guest, onlyGuestOrCurrentUser));
                });
            }

            // Day After
            questions = questions.concat(<div><br/><br/><text className="bigColoredText" key="dayAfter">DAY AFTER</text></div>)
            guestDisplay.invitationOrder.forEach((guest: Guest) => {
                const onlyGuestOrCurrentUser = guestDisplay.invitationOrder.length===1 || (currentUser!==null && currentUser!==ErrorGuest && guest.firstName===(currentUser as Guest).firstName && guest.lastName===(currentUser as Guest).lastName);
                questions = questions.concat(shuttleQuestion(guest, onlyGuestOrCurrentUser));
            });
        }
    };
    
    if (currentUser!==null && currentUser!==ErrorGuest) {
        generateQuestions();
    }
    
    const formQuestions = (
        <div>
            <br/>
            <text>{guestDisplay?.invitationCopy}</text><br/><br/><br/>
            {questions}
            <br/><br/>
            <text className="bigColoredText" key="comments">COMMENTS</text><br/><br/>
            <label>
                Any notes, questions, or dietary restrictions?<br/>
                <textarea name={"message"} id={"message"} key={"message"} placeholder="Dear Amy and Alex..." onChange={onChange} />
            </label>
        </div>
    );

    const formBody = () => {
        if (currentUser!==null && currentUser!==ErrorGuest && (currentUser as Guest).rsvpLocked===null) {
            return formQuestions;
        } else {
            const guest = (currentUser as Guest);
            return (
                <div>
                    <br/>
                    {guest.nickname === guest.rsvpLocked || guest.firstName === guest.rsvpLocked 
                        ? <text>You submitted the RSVP for your party.</text>
                        : <text>{guest.rsvpLocked} submitted the RSVP for your party.</text>}
                    <br/>
                    <text>{guestDisplay?.lockedInvitationCopy}</text><br/>
                    <br/><br/>
                    {questions}
                    <br/>
                    {guest.message && 
                        <div>
                        <br/>
                            <text className="bigColoredText" key="comments">COMMENTS</text><br/><br/>
                            <label className="simpleLabel">
                                Note submitted to the hosts:<br/><br/>
                                <text>{guest.message}</text>
                            </label>
                        </div>
                    }
                </div>
            );
        }
        
    }

    const searchName = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event);
        if (searchedName===undefined) {
            setSearchedName(event.target.value);
        } 
    }

    const form = (<div className="post">
        <text className="postTitle">RSVP</text><br/>
        {currentUser===null ? <p>Look up your invitation by full name.</p> : <br/>}
        <form onSubmit={onSubmit} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
            <div className="formWrapper">
                <div className="formBar" />
                <div className="rsvpFormBox">
                    {currentUser===null
                        ? <div className="rsvpSearchArea">
                            <label>
                                Name:&nbsp;
                                <input name="guestName" id="guestName" type="guestName" onBlur={(val) => searchName(val)} required />
                            </label>
                            {searchPressed ? <button className="rsvpSearchButton" type="button" disabled>Searching...</button> : <button className="rsvpSearchButton" type="button" onClick={(val) => search()}>Search</button>} 
                        </div>
                        : currentUser===ErrorGuest
                            ? multipleUsersFound 
                                ? <label className="simpleLabel">Multiple matches found. Please reload and search by your full name.</label>
                                : <label className="simpleLabel">We couldn't find your invitation. Please contact the hosts.</label>
                            : <label>Hi {(currentUser as Guest).nickname ?? (currentUser as Guest).firstName}!</label>
                    }
                    {userFound && formBody()}
                </div>
            </div>
            {userFound && (currentUser as Guest).rsvpLocked===null && (
                <div className="buttonBox">
                    <button type="submit">SEND</button>
                </div>
            )}

        </form>
    </div>);

    return (
        (formSubmitted 
            ? <div className="post">
                <text className="postTitle">RSVP</text><br/>
                <p>Thank you!</p>
            </div> 
            : formSubmitted===undefined 
                ? form
                : <div className="post">
                    <text className="postTitle">RSVP</text><br/>
                    <p>Sorry, we ran into an issue. Please try again later or contact Amy or Alex.</p>
                </div> )

    );
}

export default RSVPForm;
