import "../styles/App.css";
import couple from "../images/couple.jpg";
import sma from "../images/sma.jpg";
import { useContext } from "react";
import { UserContext } from "../App";
import { Guest, TeaCeremonyRole } from "../constants";

interface props {
    main: boolean;
}

const Details = (props: props) => {
    const { main } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {currentUser, setCurrentUser} = useContext(UserContext);

    const chineseTeaCeremony = (
        <div>
            <div className="columnNoSpace">
                <text className="centeredText">
                    Chinese Tea Ceremony
                </text>
                <text className="redCenteredText">
                    Friday 10/25, 11 AM<br/>
                    Salon Guitarras, Casa Parque<br/>
                    Casa de Sierra Nevada
                </text>
                <text className="smallRedText">
                    <a href="https://silkandslate.com/lin-fang-ceremony" target="_blank" rel="noopener noreferrer" className="toBlueLink">CHINESE-INSPIRED DAYTIME FORMAL ATTIRE</a>
                </text>
            </div>
            <br/>
        </div>
    );

    const teaCeremonyDescription = 
        <div>
            <p className="smallerText">
                A Chinese tea ceremony is a wedding tradition in which the bride and groom honors their elders, to symbolize the union of the two families. Our tea ceremony will include the following steps:
            </p>
            <ol>
                <li className="smallerText">The couple serves tea to their elders in order of age, showing respect and gratitude.</li>
                <li className="smallerText">After each elder takes a sip, they give the couple a lucky red envelope and say a few words.</li>
            </ol>
            <p className="smallerText">If you'd like, please dress in <a href="https://silkandslate.com/lin-fang-ceremony" target="_blank" rel="noopener noreferrer" className="toBlueLink">Chinese-inspired daytime formal attire</a>.</p>
        </div>;

    const teaCeremonyParticipant = <p className="smallerText">We will have red envelopes available to use in the ceremony. If you plan to bring valuables please be very careful and check travel advisories for what is safe to bring to Mexico. If in doubt, leave your gift at home! You can arrange delivery with Amy or Alex after the wedding. We appreciate you and want you to stay safe! xx</p>;
    const teaCeremonyViewer = <p className="smallerText">This will be a mostly standing ceremony, with chairs for our elders, lasting about an hour. As an audience member, relax and enjoy! </p>;
    const teaCeremonyStaff = <p className="smallerText">Ask Amy or Alex for the details of your staff role. If for any reason you are unable to assist in the ceremony, please let us know! You are always welcome as an audience member.</p>;

    const rehearsal = (
        <div>
            <div className="columnNoSpace">
                <text className="centeredText">
                    Ceremony Rehearsal
                </text>
                <text className="orangeCenteredText">
                    Friday 10/25, 12:15 PM<br/>
                    Cactus Terrace, Casa Parque<br/>
                    Casa de Sierra Nevada
                </text>
                <text className="redCenteredText"><b>YOU ARE REQUIRED ❤️</b></text>
            </div>
            <br/>
        </div>
    );

    const rehearsalDinner = (
        <div>
            <div className="columnNoSpace">
                <text className="centeredText">
                    Rehearsal Dinner
                </text>
                <text className="orangeCenteredText">
                    Friday 10/25, 4 PM<br/>
                    Áperi Restaurant
                </text>
                <text className="smallOrangeText">
                    <a href="https://silkandslate.com/lin-fang-rd" target="_blank" rel="noopener noreferrer" className="toBlueLink">COCKTAIL ATTIRE</a>
                </text>
            </div>
            <br/>
        </div>
    );

    const welcomeParty = (
        <div>
            <div className="columnNoSpace">
                <text className="centeredText">
                    Welcome Party
                </text>
                <text className="fuchsiaCenteredText">
                    Friday 10/25, 6:30 PM<br/>
                    Zumo Rooftop Restaurant
                </text>
                <text className="smallFuchsiaText">
                        <a href="https://silkandslate.com/lin-fang-rd" target="_blank" rel="noopener noreferrer" className="toBlueLink">COCKTAIL ATTIRE</a>
                </text>
            </div>
            <br/>
        </div>
    );

    const wedding = (
        <div className="columnNoSpace">
            <text className="centeredText">
                Wedding
            </text>
            <text className="fuchsiaCenteredText">
                Saturday 10/26, 3:30 PM<br/>
                Casa Parque, Casa de Sierra Nevada
            </text>
            <text className="smallFuchsiaText">
                    <a href="https://silkandslate.com/lin-fang-attire" target="_blank" rel="noopener noreferrer" className="toBlueLink">CREATIVE FORMAL ATTIRE</a>
            </text>
        </div>
    );

    return (
        <div>
            {main ? (
                <div className="wrapper">
                    <div className="row">
                        <img src={couple} className="couple" alt="couple" />
                    </div><br />
                    <div className="column">
                        <text className="mediumText">
                            The wedding will be held on Saturday, October 26, 2024. 
                        </text>
                        <text className="mediumText">
                            We'll have a ceremony, followed by cocktails, dinner, and dancing.
                        </text>
                        <br/>
                        <div className="rowStretch">
                            <div className="columnNoSpace">
                                <text>
                                    CEREMONY:
                                </text>
                                <text className="coloredText">
                                    3:30 PM
                                </text>
                            </div>
                            <div className="columnNoSpace">
                                <text>
                                    END OF FESTIVITIES:
                                </text>
                                <text className="coloredText">
                                    1 AM
                                </text>
                            </div>
                        </div>
                        <br/>
                        <text className="smallText">
                            CASA PARQUE
                        </text>
                        <text className="smallText">
                            CASA DE SIERRA NEVADA
                        </text>
                        <text className="tinyText">
                            &#40;<a href="https://maps.app.goo.gl/uNrq8bhbrFauRJWs8" target="_blank" rel="noopener noreferrer" className="greenLink">
                                directions
                            </a>&#41;
                        </text>

                        <br/>
                        <text className="mediumSmallText">
                            <i><a href="https://silkandslate.com/lin-fang-attire" target="_blank" rel="noopener noreferrer" className="greenLink">Creative formal attire</a></i>
                        </text>
                    </div>
                </div>
            ) : (
                <div className="wrapper">
                    <div className="column">
                        <text className="postTitle">
                            Weekend Events
                        </text>
                        <br/>
                        <text className="mediumSmallText">
                            <i>Open events and special invitations.</i>
                        </text>
                        <br/>
                            {currentUser && (currentUser as Guest).teaCeremonyInvited && chineseTeaCeremony}
                            {currentUser && (currentUser as Guest).rehearsalRequired && rehearsal}
                            {currentUser && (currentUser as Guest).rehearsalDinnerInvited && rehearsalDinner}
                            {welcomeParty}
                            {wedding}
                    </div><br/>
                    <div className="row">
                        <img src={sma} className="sma" alt="sma" />
                    </div>
                </div>
            )}
            <br/>
            <br/>
            {!main && <div className="column">
                {currentUser && (currentUser as Guest).teaCeremonyInvited && (currentUser as Guest).teaCeremonyRole === TeaCeremonyRole.Participant && 
                    <div>
                        <text className="smallText">&#40;You are invited to attend as an honored participant in our tea ceremony.&#41;</text>
                        {teaCeremonyDescription}
                        {teaCeremonyParticipant}
                    </div>
                }
                {currentUser && (currentUser as Guest).teaCeremonyInvited && (currentUser as Guest).teaCeremonyRole === TeaCeremonyRole.Viewer && 
                    <div>
                        <text className="smallText">&#40;You are invited to attend as an audience member of our tea ceremony.&#41;</text>
                        {teaCeremonyDescription}
                        {teaCeremonyViewer}
                    </div>
                }
                {currentUser && (currentUser as Guest).teaCeremonyInvited && (currentUser as Guest).teaCeremonyRole === TeaCeremonyRole.Staff && 
                    <div>
                        <text className="smallText">&#40;You are invited to attend and help run the tea ceremony.&#41;</text>
                        {teaCeremonyDescription}
                        {teaCeremonyStaff}
                    </div>
                }
                {currentUser && (currentUser as Guest).teaCeremonyInvited && (currentUser as Guest).teaCeremonyRole === TeaCeremonyRole.NotInvited && 
                    <div>
                        <text className="smallText">&#40;You are invited to attend our Chinese tea ceremony.&#41;</text>
                        {teaCeremonyDescription}
                    </div>
                }
            </div>}
        </div>
    );
};

export default Details;
