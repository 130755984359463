import "../styles/App.css";

import Invitation from "../components/Invitation";
import Details from "../components/Details";
import RSVPForm from "../components/RSVPForm";

import venmoAmy from "../images/venmoamy.png";
import venmoAlex from "../images/venmoalex.png";
import zelleAmy from "../images/zelleamy.png";
import zelleAlex from "../images/zellealex.png";
import { handleScroll } from "../constants";

function Home() {
    const sma = (
        <div className="post">
            <text className="postTitle">San Miguel de Allende</text>
            <p>San Miguel de Allende is a beautiful town located in Central Mexico, four hours away from Mexico City. It's a place with a ton of local flavor, hosting festivals several weekends a month, with a unique tradition of parading mojigangas&mdash;gigantic papier-mâché parodies of iconic figures, such as La Catrina and Frida Kahlo&mdash;through its streets. Much of the town's Spanish colonial architecture has been preserved and maintained through the years. Readers of <i>Travel + Leisure</i> ranked it the <a href="https://www.travelandleisure.com/wba-2024-cities-world-8660857" target="_blank" rel="noopener noreferrer">best city in the world</a>!</p>
            <p>When we visited, we fell in love with the cobblestone roads, Mexican bread, street sorbet, local library, sunset, and main square lively with mariachi at nighttime. It's a lowkey and peaceful town, where the locals are artists and craftspeople, in which you can spend each morning waking up as the bakeries open, sitting in the square with your coffee, and perusing the market at your leisure.</p>
        </div>
    );

    const travel = (
        <div className="post">
            <text className="postTitle">Travel</text>
            <p>With an unexpired US passport, you will be able to enter Mexico without needing a visa.</p>
            <p>The closest airports are QRO and BJX. There are direct flights from Houston, Dallas, Atlanta, Los Angeles and Chicago. From the US, you will likely layover in one of those cities. </p>
            <p>We recommend flying in early on the 24th to rest and explore San Miguel de Allende before our welcome event and leaving on or after the 27th.</p>
        </div>
    );

    const transportation = (
        <div className="post">
            <text className="postTitle">Airport Transfers</text>
            <p>Contact our travel coordinator WedAways at reservations@wedaways.com to book your airport transfer from QRO or BJX to San Miguel de Allende. Please be sure to reference the Lin Fang Wedding on 10.26.2024 when reaching out.</p>
            <p>If you'd like to book the airport transfer yourself, WedAways recommends <a href="http://www.viajessanmiguel.com/" target="_blank" rel="noopener noreferrer">Viajes</a>, a reputable private car company, which we used the last time we visited San Miguel de Allende. <a href="https://www.blacklane.com" target="_blank" rel="noopener noreferrer">Blacklane</a> is another reputable car service. </p>
            <p><a href="https://www.bajiogo.com/" target="_blank" rel="noopener noreferrer">BajioGo Shuttle</a> specializes in transfers to and from the León, Querétaro, and Mexico City airports. Shared shuttle service pairs you up with other passengers who are arriving or departing at the same time as you, and can be more affordable. Our contact at WedAways has not personally used this service before. </p>
        </div>
    );

    const accommodations = (
        <div className="post">
            <text className="postTitle">Hotels</text>
            <p>Our recommended hotel is our wedding venue, Casa de Sierra Nevada, A Belmond Hotel. </p>
            <p>The following hotels, with links, can be booked through our travel coordinator WedAways:</p>
            <ul>
                <li><a href="https://www.belmond.com/hotels/north-america/mexico/san-miguel-de-allende/belmond-casa-de-sierra-nevada" target="_blank" rel="noopener noreferrer">Casa de Sierra Nevada, A Belmond Hotel</a></li>
                <li><a href="https://www.rosewoodhotels.com/en/san-miguel-de-allende" target="_blank" rel="noopener noreferrer">Rosewood San Miguel</a></li>
                <li><a href="https://www.liveaqua.com/en/hotels-resorts/live-aqua-urban-resort-san-miguel-de-allende" target="_blank" rel="noopener noreferrer">Live Aqua San Miguel</a></li>
                <li><a href="https://hotelmatilda.com/" target="_blank" rel="noopener noreferrer">Hotel Matilda</a></li>
                <li><a href="https://www.ourhabitas.com/san-miguel-de-allende/" target="_blank" rel="noopener noreferrer">Our Habitas</a></li>
            </ul>
            <p>These other options to book on your own also come recommended by WedAways:</p>
            <ul>
                <li><a href="https://www.haciendaelsantuario.com/en/" target="_blank" rel="noopener noreferrer">Hacienda El Santuario</a></li>
                <li><a href="https://www.selina.com/mexico/san-miguel-de-allende/" target="_blank" rel="noopener noreferrer">Selina San Miguel</a></li>
                <li><a href="https://casa1810.com/centro/" target="_blank" rel="noopener noreferrer">Casa 1810</a></li>
                <li><a href="https://www.hyatt.com/en-US/hotel/mexico/numu/bjxub" target="_blank" rel="noopener noreferrer">Numu Boutique Hotel</a></li>
            </ul>
        </div>
    );

    const roomReservations = (
        <div className="post">
            <text className="postTitle">Room Reservations</text>
            <p>When making a room reservation at Casa de Sierra Nevada, A Belmond Hotel, or the other four main options, use one of these methods:</p>
            <ul>
                <li>Casa de Sierra Nevada Direct Booking
                    <ul>
                        <li>reservaciones.csn@belmond.com</li>
                        <li>+52 415 152 7040</li>
                    </ul>
                </li>
                <li>Casa de Sierra Nevada and/or Other Options &#40;WedAways Assisted Booking&#41;
                    <ul>
                            <li>reservations@wedaways.com</li>
                    </ul>
                </li>
            </ul>
            <p>Please be sure to reference the Lin Fang Wedding on 10.26.2024 when reaching out to any of the above.</p>
        </div>
    );

    const roomingQuestions = (
        <div className="post">
            <text className="postTitle">Rooming Questions</text>
            <p>For specific questions about rooming details at Casa de Sierra Nevada, such as layout, bed size, etc., please reach out to the hotel at reservaciones.csn@belmond.com.
            </p>
        </div>
    );

    const activities = (
        <div className="post">
            <text className="postTitle">Individual Activities and Tours</text>
            <ul>
                <li>Casa de Sierra Nevada Concierge &#40;$$$&#41;
                    <ul>
                        <li>+52 415 152 7040</li>
                        <li>octavio.tovar@belmond.com and frontdesk.csn@belmond.com</li>
                    </ul>
                </li>
                <li><a href="https://www.projectexpedition.com/location/mexico/guanajuato/san-miguel-de-allende/?utm_source=wedaways-22759-pamela-40089&utm_medium=referral&utm_campaign=generate" target="_blank" rel="noopener noreferrer">Project Expedition</a> &#40;$$&#41;</li>
                <li><a href="https://www.travelandleisure.com/san-miguel-de-allende-travel-guide-5429388" target="_blank" rel="noopener noreferrer">Travel + Leisure Travel Guide</a></li>
            </ul>
        </div>
    );

    const shuttle = (
        <div className="post">
            <text className="postTitle">Shuttle to Mexico City</text>
            <p>We have a few private shuttles leaving San Miguel de Allende on Sunday 10/27 to Mexico City. The ride is approximately 3.5 hours. The cost per seat is $100-200 USD and depends on the final headcount. Please indicate on your RSVP whether you would like to book a seat.
            </p>
        </div>
    );

    const faq = (
        <div className="post">
            <text className="postTitle">FAQs</text><br/><br/>
            <text className="mediumSmallText"><i>Can I bring a plus one?</i></text>
            <p className="test">Due to capacity limits we are not able to extend our invite to plus ones. Please refer to the front of your envelope to check the name&#40;s&#41; on the invitation. Thank you for understanding!</p>
            <text className="mediumSmallText"><i>Should I travel to other cities?</i></text>
            <p className="test">Mexico is a beautiful place. However, please reserve all extra travel for after the wedding. We want to remain conscious of the rise of COVID cases and vigilant to prevent it from spreading to our guests.</p>
            <text className="mediumSmallText"><i>What is the weather like?</i></text>
            <p className="test">Late October should be just after the rainy season. We're expecting clear sunny skies, few mosquitoes, and temperatures in the 80s.</p>
            <text className="mediumSmallText"><i>Where is Casa Parque &#40;the wedding venue&#41;?</i></text>
            <p className="test">Casa Parque is a ten minute walk from the main hotel. You can search for Restaurante del Parque on your map or <a href="https://maps.app.goo.gl/uNrq8bhbrFauRJWs8" target="_blank" rel="noopener noreferrer">click here</a> for directions.</p>
        </div>
    );

    const registry = (
        <div className="post">
            <text className="postTitle">Registry</text><br/><br/>
            <text className="mediumSmallText"><i>Your presence is so special to us.</i></text>
            <p className="test">However, if you'd like to make a contribution to our wedding fund, the links are below. We are deeply grateful to you.</p>
            <br/>
            <div className="wrapper">
                <div className="fundColumnLeft">
                    <text className="mediumSmallText"><i>Honeymoon Fund</i></text>
                    <p>We are postponing our honeymoon to 2025 and hoping to travel to Italy and the south of France.</p>
                    <p>Help make our dreams come true! We'll send pics from wherever we end up.</p>
                    <div className="columnNoSpaceCentered">
                        <p className="test"><a href="https://venmo.com/?txn=pay&audience=private&recipients=AlexFang&note=For%20the%20Honeymoon%20Fund%20%F0%9F%A5%82" target="_blank" rel="noopener noreferrer">Venmo @AlexFang</a></p>
                        <a className="venmo" href="https://venmo.com/?txn=pay&audience=private&recipients=AlexFang&note=For%20the%20Honeymoon%20Fund%20%F0%9F%A5%82" target="_blank" rel="noopener noreferrer">
                            <img src={venmoAlex} className="venmo" alt="venmoAlex" />
                        </a>
                        <p className="test"><a href="https://enroll.zellepay.com/qr-codes?data=eyJuYW1lIjoiQWxleGFuZGVyIiwidG9rZW4iOiIxMzAxNzg3MzI3OCJ9" target="_blank" rel="noopener noreferrer">Zelle Alex</a></p>
                        <a className="zelle" href="https://enroll.zellepay.com/qr-codes?data=eyJuYW1lIjoiQWxleGFuZGVyIiwidG9rZW4iOiIxMzAxNzg3MzI3OCJ9" target="_blank" rel="noopener noreferrer">
                            <img src={zelleAlex} className="zelle" alt="zelleAlex" />
                        </a>
                    </div>
                </div><br/>
                <div className="fundColumnRight">
                    <text className="mediumSmallText"><i>Home Reno Fund</i></text>
                    <p>In the far-flung future, we'd like to prepare our tiny house for long-term living and maybe a babe or two... </p>
                    <p>Short-term goals include skylights that let in more of that frittered, rain-sullied Seattle sunlight. Long-term goals include adding a second floor &#40;and a second bathroom&#41;! We'll keep you apprised of all developments.</p>
                    <div className="columnNoSpaceCentered">
                        <p className="test"><a href="https://venmo.com/?txn=pay&audience=private&recipients=Amy-YQ-Lin&note=For%20the%20Home%20Reno%20Fund%20%F0%9F%8E%89" target="_blank" rel="noopener noreferrer">Venmo @Amy-YQ-Lin</a></p>
                        <a className="venmo" href="https://venmo.com/?txn=pay&audience=private&recipients=Amy-YQ-Lin&note=For%20the%20Home%20Reno%20Fund%20%F0%9F%8E%89" target="_blank" rel="noopener noreferrer">
                            <img src={venmoAmy} className="venmo" alt="venmoAmy" />
                        </a>
                        <p className="test"><a href="https://enroll.zellepay.com/qr-codes?data=eyJ0b2tlbiI6IjEyNDA0MjE3OTA2IiwibmFtZSI6IkFteSJ9" target="_blank" rel="noopener noreferrer">Zelle Amy</a></p>
                        <a className="zelle" href="https://enroll.zellepay.com/qr-codes?data=eyJ0b2tlbiI6IjEyNDA0MjE3OTA2IiwibmFtZSI6IkFteSJ9" target="_blank" rel="noopener noreferrer">
                            <img src={zelleAmy} className="zelle" alt="zelleAmy" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

    const linAndFang = (
        <div className="hashtag">
            <text className="postSubtitleHashtag">#</text>
            <text className="postSubtitle">LinAndFang</text>
        </div>
    );

    const postDivider = <div className="postDivider" />;

    return (
        <div className="Home">
            <div className="wrapper">
                {
                        <div className="column">
                            <Invitation />
                            <div className="postDivider" />
                            <br/><br/>
                            <Details main={true} />
                            <div className="postDivider" id="schedule"/>
                            <br/><br/>
                            <Details main={false} />
                        </div>
                }
            </div>

            <div className="container">
                {postDivider}
                {sma}
                {postDivider}
                {travel}
                {postDivider}
                {accommodations}
                {postDivider}
                {roomReservations}
                {postDivider}
                {roomingQuestions}
                {postDivider}
                {transportation}
                {postDivider}
                {shuttle}
                {postDivider}
                {activities}
                <div className="postDivider" id="faq"/>
                {faq}
                <div className="postDivider" id="registry"/>
                {registry}
                <div className="postDivider" id="form"/>
                {<RSVPForm />}
                {postDivider}
                {linAndFang}
                <div className="buttonBox">
                    <button type="button" className="smallButtons" onClick={e => handleScroll("top")}>
                        back to top <text className="upArrow">&uarr;</text>
                    </button>
                </div>
                <div className="wrapper">
                    <text className="copyright">&#169; 2024 Amy and Alexander Lin Fang</text>
                </div>
            </div>
        </div>
    );
}

export default Home;
