export const RSVP = Object.freeze({
    Yes: "yes",
    No: "no"
});

export enum Meal {
    Meat = "meat",
    Seafood = "seafood",
    Vegetarian = "vegetarian",
};

export const Event = Object.freeze({
    TeaCeremony: "the tea ceremony",
    CeremonyAndReception: "the wedding day",
    RehearsalDinner: "the rehearsal dinner",
    WelcomeParty: "the welcome party"
});

export const Shuttle = Object.freeze({
    Yes: "yes",
    No: "no",
});

export const PageDisplay = {
    Home: "home",
    AboutMe: "aboutme",
    ProfessionalServices: "professionalservices",
};

export enum TeaCeremonyRole {
    Participant = "participant",
    Viewer = "viewer",
    Staff = "staff",
    NotInvited = "no"
};

export type Guest = {
    title: string;
    firstName: string;
    lastName: string;
    nickname: string | null;
    pronoun1: string; // he, her, they, I
    pronoun2: string; // him, her, them, me
    pronoun3: string; // possessive: his, her, their, your
    id: string;
    rsvpId: string;
    rsvpLocked: string | null;
    rsvpGoing: boolean | null;
    mealChoice?: Meal;
    rehearsalRequired: boolean;
    rehearsalDinnerInvited: boolean;
    rehearsalDinnerGoing: boolean | null;
    rehearsalDinnerMealChoice1?: Meal;
    rehearsalDinnerMealChoice2?: Meal;
    teaCeremonyInvited: boolean;
    teaCeremonyGoing: boolean | null;
    teaCeremonyRole?: TeaCeremonyRole;
    welcomePartyGoing: boolean | null;
    bus: boolean | null;
    message: string | null;
};

export const ErrorGuest: Guest = Object.freeze({
    title: "ERROR",
    firstName: "ERROR",
    lastName: "ERROR",
    nickname: null,
    pronoun1: "they",
    pronoun2: "them",
    pronoun3: "their",
    id: "0",
    rsvpId: "0",
    rsvpLocked: null,
    rsvpGoing: null,
    mealChoice: undefined,
    rehearsalRequired: false,
    rehearsalDinnerInvited: false,
    rehearsalDinnerGoing: null,
    rehearsalDinnerMealChoice1: undefined,
    rehearsalDinnerMealChoice2: undefined,
    teaCeremonyInvited: false,
    teaCeremonyGoing: null,
    teaCeremonyRole: undefined,
    welcomePartyGoing: null,
    bus: null,
    message: null
});

export type GuestResponseStatus = {
    status: number;
    text: string;
    guest: Guest;
};

export type InvitationResponseStatus = {
    status: number;
    text: string;
    invitations: Guest[];
};

export type RSVPSubmitResponseStatus = {
    status: number;
    text: string;
}

export function handleScroll(elementName: string) {
    document.getElementById(elementName)?.scrollIntoView({behavior: 'smooth'});
}
